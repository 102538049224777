import {
    getModule,
    Module,
    MutationAction,
    VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {CorporateRequest, RegisterRequest} from "@/api/corporate-shop/request";
import { CorporateResponse, CorporateResult } from "@/api/corporate-shop/response";
import * as CorporateAPI from "@/api/corporate-shop";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "corporate-shop/get";

/**
 * 会社概要情報取得API（/get-corporate）のレスポンスを管理するストアモジュール
 */
@Module({dynamic: true, store, name: MODULE_NAME, namespaced: true})
class Get extends VuexModule {
    // state
    corporateResponse: CorporateResponse = {} as CorporateResponse;

    // getters
    get isSuccess() {
        return isSuccess(this.corporateResponse);
    }
    get getMessage() {
        return this.corporateResponse.message;
    }
    get getId() {
        return this.corporateResponse.results ? this.corporateResponse.results.id : null;
    }
    get getMainShopId() {
        return this.corporateResponse.results.mainShopId;
    }
    get getDescription() {
        return this.corporateResponse.results.description;
    }
    get getImagePath() {
        return this.corporateResponse.results.imagePath;
    }
    get getImagePathPc() {
        return this.corporateResponse.results.imagePathPc;
    }
    get getImagePathSp() {
        return this.corporateResponse.results.imagePathSp;
    }
    get getImagePathTab() {
        return this.corporateResponse.results.imagePathTab;
    }
    get getState() {
        return this.corporateResponse.results.state;
    }
    get getAddress1() {
        return this.corporateResponse.results.address1;
    }
    get getAddress2() {
        return this.corporateResponse.results.address2;
    }
    get getGeoInputFlg() {
        return this.corporateResponse.results.geoInputFlg;
    }
    get getLat() {
        return this.corporateResponse.results.lat;
    }
    get getLng() {
        return this.corporateResponse.results.lng;
    }
    get getIsPreview() {
        return this.corporateResponse.results.isPreview;
    }

    // MutationActions
    @MutationAction
    async corporate(corporateRequest: CorporateRequest) {
        const corporateResponse = await CorporateAPI.corporate(corporateRequest);
        return {
            corporateResponse
        };
    }

    @MutationAction
    async clear() {
        this.corporateResponse = {} as CorporateResponse;
        return {
            corporateResponse: {} as CorporateResponse
        };
    }
}

export default getModule(Get);
