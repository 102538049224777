import { Component, Mixins, Prop } from "vue-property-decorator";
import Draggable from "vuedraggable";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
// @ts-ignore
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { RegisterRequest } from "@/api/corporate-shop/request";
import CorporateGetApi from "@/store/corporate-shop/get";
import CorporateRegisterAPI from "@/store/corporate-shop/register";
import Flash, { ErrorAlert } from "@/store/common/flash";
import _ from "lodash";
import Loading from "@/store/loading";
import { CorporateRequest } from "@/api/corporate-shop/request";
import UlFileRegister from "@/components/UlFileRegister.vue";
import {UlFileRegisiterTabType} from "@/utils/enums";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import WindowOpen from "@/utils/window-open";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import { GetRequest as GetAgreementRequest } from "@/api/shop-agreement/request";
import Admin from "@/store/admin/admin";
import StateGet from "@/store/state/get";
import { BusinessServiceStorage } from "@/store/business-service-storage";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIFormRow,
    Draggable,
    UlFileRegister,
    quillEditor
  }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
  @Prop({type: String, required: true})
  shopId!: string;

  // タイトル
  headingMain = "会社概要管理";
  headingSub = "Corporate management";

  initImageUrl = null as string | null;
  allowedTypes = ["image/jpeg", "image/png", "image/gif","image/jpg"];
  tabType = UlFileRegisiterTabType.Photo;

  // パンくず
  breadCrumbs = [
    {text: "Webアプリ", disabled: true},
    {text: "会社概要管理", disabled: true},
    {
      text: "会社概要情報一覧",
      disabled: false,
      to: {name: "company-profile-management"}
    },
    {text: "設定", disabled: true}
  ];

  editorOption = {
    modules: {
      toolbar: [
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"]
      ]
    }
  };

  // 契約状態
  agreements = {
    ap: false,
    line: false,
    web: false
  };

  // メニュー表示プロパティ
  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };
  // ------------
  // 変動値
  // ------------

  // ローディングステータス
  isLoading = false;

  // 入力データ
  inputData = {
    /** 会社概要情報ID */
    id: null as null | number,
    /** 店舗ID */
    mainShopId: Number(this.shopId),
    /** 会社概要 */
    description: "",
    /** 画像編集フラグ */
    imageEditFlg: 0,
    /** Base64エンコードされた画像データ */
    imagePath: null as string | null,
    /** t_shop_image_histories.id */
    imageId: null as number | null,
    /** Base64エンコードされた画像データ(PC用) */
    imagePathPc: null as string | null,
    /** Base64エンコードされた画像データ(SP用) */
    imagePathSp: null as string | null,
    /** Base64エンコードされた画像データ(タブレット用) */
    imagePathTab: null as string | null,
    /** 都道府県 */
    state: "",
    /** 住所1 */
    address_1: "",
    /** 住所2 */
    address_2: "",
    /** 緯度経度設定 */
    geoInputFlg: null as number | null,
    /** 緯度 */
    lat: null as string | null,
    /** 経度 */
    lng: null as string | null,
    /** 緯度経度 */
    geo: "",
    /** プレビュー */
    isPreview: null as number | null
  }  as RegisterRequest;
  
  /** OEM事業者ID */
  businessId= 0 as number;

  //ローディングステータス
  get loading() {
    return Loading.isLoading;
  }

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  /**
   * createdライフサイクルフック
   */
  async created() {

    const state = StateGet.get();
    let getErrorMessage = null as string | null;
    if (!StateGet.isSuccess) {
      getErrorMessage = StateGet.getMessage;
    }
    if (getErrorMessage) {
      await Flash.setErrorNow({
        message: getErrorMessage,
        showReloadButton: true
      });
      return;
    }
    // 契約情報を取得する
    if (this.shopId) {
      let requestAgrement = {
        shopId: String(this.shopId)
      } as GetAgreementRequest;
      await ShopAgreementAPI.get(requestAgrement);
      if (!ShopAgreementAPI.isSuccess) {
        await Flash.setErrorNow({
          message: "",
          showReloadButton: true
        } as ErrorAlert);
        return;
      } else {
        const AgreementItems = ShopAgreementAPI.getagreementsList;
        AgreementItems.forEach((item: { agreementsType: number }) => {
          if (item.agreementsType == 1) {
            this.agreements.ap = true;
          } else if (item.agreementsType == 2) {
            this.agreements.line = true;
          } else if (item.agreementsType == 3) {
            this.agreements.web = true;
          }
        });
      }
    } else {
      // スーパーユーザの場合は全て契約
      if (Admin.isAdmin) {
        this.agreements.ap = true;
        this.agreements.line = true;
        this.agreements.web = true;
      }
    }


    await this.get();
    this.syncParams();
  }

  async mounted(){
    const storage = BusinessServiceStorage.getLocalStorage();
    this.businessId = storage.id ? storage.id : 0;
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await CorporateGetApi.clear();
    await CorporateRegisterAPI.clear();
  }

  setFile(data: string, id: number | null) {
    this.inputData.imageEditFlg = 1;
    this.inputData.imagePath = data;
    this.inputData.imageId = id;
  }

  unsetFile() {
    this.inputData.imageEditFlg = 1;
    this.inputData.imagePath = null;
    this.inputData.imageId = null;
  }

  async webPreview() {
    this.inputData.isPreview = 2;
    await this.callRegisterApi();
    if (CorporateRegisterAPI.isSuccess) {
      WindowOpen.webPreview(CorporateRegisterAPI.getPreviewUrl);
    }
  }

  /**
   * 会社概要情報の取得処理
   */
  async get(): Promise<boolean> {
    this.isLoading = true;
    if (CorporateGetApi.isSuccess) {
      return true;
    }
    await CorporateGetApi.clear();
    const request = this.createGetRequest();
    await CorporateGetApi.corporate(request);
    if (!CorporateGetApi.isSuccess) {
      await Flash.setErrorNow({
        message: CorporateGetApi.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
    return CorporateGetApi.isSuccess;
  }

  /**
   * 更新する
   */
  async register() {
    this.inputData.isPreview = 0;
    await this.callRegisterApi();
    if (CorporateRegisterAPI.isSuccess) {
      await this.redirectWithSuccessAlert(
          "保存しました。",
          "/company-profile-management"
      );
    } else {
      await Flash.setErrorNow({
        message: CorporateRegisterAPI.getMessage,
        showReloadButton: false
      });
    }
  }

  private async callRegisterApi() {
    await Flash.clear();
    this.isLoading = true;
    await CorporateRegisterAPI.register(this.createRegisterRequest());
    if (!CorporateRegisterAPI.isSuccess) {
      await Flash.setErrorNow({
        message: CorporateRegisterAPI.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * dataから情報取得用のリクエストを作成する
   */
  private createGetRequest() {
    return {shopId: this.shopId} as CorporateRequest;
  }

  /**
   * dataから登録更新用のリクエストを作成する
   */
  private createRegisterRequest() {
    const request = _.cloneDeep(this.inputData);

    // 緯度・経度設定が自動取得の場合、リクエスト値から緯度・経度を削除する
    if (request.geoInputFlg === 0) {
      delete request.lat;
      delete request.lng;
    }

    return request;
  }

  /**
   * APIのレスポンスをdataにsyncする
   */
  private syncParams() {
    this.inputData.id = CorporateGetApi.getId;
    this.inputData.mainShopId = CorporateGetApi.getMainShopId;
    this.inputData.description = CorporateGetApi.getDescription;
    this.inputData.imagePath = CorporateGetApi.getImagePath;
    this.inputData.imagePathPc = CorporateGetApi.getImagePathPc;
    this.inputData.imagePathSp = CorporateGetApi.getImagePathSp;
    this.inputData.imagePathTab = CorporateGetApi.getImagePathTab;
    this.inputData.state = CorporateGetApi.getState;
    this.inputData.address_1 = CorporateGetApi.getAddress1;
    this.inputData.address_2 = CorporateGetApi.getAddress2;
    this.inputData.geoInputFlg = CorporateGetApi.getGeoInputFlg;
    this.inputData.lat = CorporateGetApi.getLat;
    this.inputData.lng = CorporateGetApi.getLng;
    this.inputData.isPreview = CorporateGetApi.getIsPreview;
    this.initImageUrl = this.inputData.imagePath;
  }

  /**
   * 都道府県を取得する
   */
  get stateItems() {
    return StateGet.getItems;
  }
}